<template>
  <div>
  

  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName.toUpperCase( ) || userData.username.toUpperCase( ) }}
        </p>
        <span class="user-status">{{ userData.role.toUpperCase() }}</span>
      </div>

      <feather-icon
        v-if="!userData.fullName"
        icon="UserIcon"
        size="22"
      />
      </b-avatar>
    </template>
    <!--
    <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />
-->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      v-b-modal.modal-prevent-closing
    >
      <feather-icon
        size="16"
        icon="KeyIcon"
        class="mr-50"
      />
      <span>Password</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="輸入你的新密碼 Submit Your New Password"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
      <b-form-group
          :state="nameState"
          label="現有密碼 Exist Password"
          label-for="name-input"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password-input"
            type="password"
            v-model="exist_password"
            rules="required|password"
            required
          />
        </b-form-group>
        <b-form-group
          :state="nameState"
          label="新密碼 New Password"
          label-for="name-input"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password-input"
            type="password"
            v-model="password"
            rules="required|password"
            required
          />
        </b-form-group>
        <b-form-group
          :state="nameState"
          label="重複新密碼 New Password"
          label-for="name-input"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password-input"
            type="password"
            v-model="confirm_password"
            rules="required|password"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, VBModal, BAvatar,BButton, BModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  directives: {
    Ripple,
  },
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    VBModal,
    BModal,
    BForm,
    ToastificationContent,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      password:null,
      nameState: null,
      confirm_password:null,
      exist_password:null
    }
  },
  created() {

    if (this.userData.client_id == 10) {
      console.log("valleyFit Detect")
      this.logout()
    }
    var date = Math.round(new Date().getTime() / 1000)
    if (this.userData.expire) {
      if (this.userData.expire < date) {
        this.$forceUpdate();
        this.logout()
      }
    } else {
      this.$forceUpdate();
      this.logout()
    }
    if (this.userData.client_expired === true) {
      this.showToast("primary","Your CloudFit account expired on "+this.userData.client_expire_date,9999999)
    }
  },
  methods: {
    showToast(variant, message, timeout) {
      this.$toast({
        component: ToastificationContent,
        draggable: true,
        draggablePercent: 100,
        props: {
          title: 'Account Expired',
          icon: 'BellIcon',
          hideClose: true,
          text: message,
          variant,
          position: 'top-center',
          draggable: true,
          draggablePercent: 100,
        },
      },
      {
        id: 'expire_alert',
        draggable: true,
        draggablePercent: 100,
        timeout,
        position: 'top-center',
      })
    },
    checkFormValidity() {
         const valid = this.$refs.form.checkValidity()
         this.nameState = valid

         return valid
       },
       handleOk(bvModalEvt) {
         // Prevent modal from closing
         bvModalEvt.preventDefault()
         // Trigger submit handler
         this.handleSubmit()
       },
       handleSubmit() {
         // Exit when the form isn't valid
         if (!this.checkFormValidity()) {
           return
         }
         if (this.password != this.confirm_password) {
          this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: '輸入的密碼不一致'
                 },
               })
               return false
         }
          this.$http.post(process.env.VUE_APP_API_BASE+'/staff/password/', {
            'password':this.password,
            'exist_password': this.exist_password,
          })
           .then(res => {
             if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.password = null
               this.exist_password = null
               this.confirm_password = null
               this.$refs['my-modal'].hide()
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
             }
           })

       },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      delete this.$http.defaults.headers.Token
      delete this.$http.defaults.headers.Authorization
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      this.$toast.dismiss('expire_alert')  
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
