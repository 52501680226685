export default [
  {
    header: 'MAIN FUNCTIONS',
    action: 'read',
    resource: 'basic',

  },
  {
    title: 'Member List',
    route: 'member-list',
    icon: 'UsersIcon',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'pt',
    // },
  },
  {
    title: 'PT Schedule',
    route: 'calendar',
    icon: 'CalendarIcon',
    iconPack: 'feather',
    module: 'package',
    // acl: {
    action: 'read',
    resource: 'basic',
    action: 'read',
    resource: 'package',
    // },
  },
  {
    title: 'Class List',
    module: 'class',
    route: 'class-list',
    icon: 'TrelloIcon',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'class',
    action: 'read',
    resource: 'staff',

    // },
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'ActivityIcon',
    module: 'entry',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'staff',
    // },
  },
  {
    header: 'Report',
    action: 'read',
    resource: 'basic',
  },
  {
    title: 'Daily Sales',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Transaction',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-transacion',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Personal Training',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'package',
    route: 'report-personal-training',
    action: 'read',
    resource: 'basic',
  },
  {
    title: 'Membership',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'membership',
    route: 'report-membership',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Online Payment',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'credit_card',
    route: 'report-online-payment',
    action: 'read',
    resource: 'credit_card',
    resource: 'staff',
  },
  {
    title: 'Entry',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-entry',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'Stranger',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-stranger',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'Unpay',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-nonpay',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Installment',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'credit_card',
    route: 'report-installment',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Commission',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-commission',
    action: 'read',
    resource: 'pt',
  },
  {
    title: 'Package (PT)',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'package',
    route: 'report-pt-package',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'Class Enrollment',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'class',
    route: 'report-groupclass-enrollment',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Package (Group)',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'class',
    route: 'report-groupclass',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Roster',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'roster',
    action: 'read',
    resource: 'roster',
    route: 'report-roster',
  },
  {
    title: 'Product',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    action: 'read',
    resource: 'manager',
    route: 'report-product',
  },
  {
    title: 'Class Schedule',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    module: 'class',
    route: 'report-class-schedule',
    action: 'read',
    resource: 'manager',
  },
  {
    title: 'Rental',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-rental',
    module: 'staff_package',
    action: 'read',
    resource: 'admin',
  },
  {
    title: 'Rental Booking',
    icon: 'PieChartIcon',
    iconPack: 'feather',
    route: 'report-rental-booking',
    module: 'staff_package',
    action: 'read',
    resource: 'admin',
  },
  {
    header: 'Finance',
    action: 'read',
    resource: 'finance',
    module: 'finance',
    action: 'read',
    resource: 'admin',

  },
  {
    title: 'Expenditure',
    route: 'expenditure',
    icon: 'ShoppingBagIcon',
    iconPack: 'feather',
    module: 'finance',
    // acl: {
    action: 'read',
    resource: 'finance',
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Invoice',
    route: 'invoice',
    module: 'finance',
    icon: 'DollarSignIcon',
    iconPack: 'feather',
    action: 'read',
    resource: 'finance',
    // acl: {
    action: 'read',
    resource: 'admin',

    // },
  },
  {
    header: 'System Config',
    action: 'read',
    resource: 'staff',
  },
  {
    title: 'Class Import',
    route: 'class-import',
    module: 'class',
    icon: 'fa-solid fa-file-import',
    iconPack: 'fontawesome',
    // acl: {
    action: 'read',
    resource: 'class',
    resource: 'staff',


    // },
  },
  {
    title: 'User',
    route: 'staff-list',
    icon: 'fa-solid fa-user',
    iconPack: 'fontawesome',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Room',
    route: 'room-list',
    icon: 'fa-solid fa-people-roof',
    iconPack: 'fontawesome',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Product',
    route: 'product-list',
    icon: 'fa-solid fa-cart-shopping',
    iconPack: 'fontawesome',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Package',
    route: 'package-list',
    icon: 'TrelloIcon',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Membership',
    route: 'membership-list',
    module: 'membership',
    icon: 'TrelloIcon',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
  {
    title: 'Configuration',
    route: 'system-configuration',
    icon: 'SettingsIcon',
    iconPack: 'feather',
    // acl: {
    action: 'read',
    resource: 'admin',
    // },
  },
]
