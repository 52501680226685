<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-modal
      ok-only
      ref="board-modal"
      ok-title="確定"
      centered
      button-size="sm"
      v-b-modal.modal-lg
      :title=board.title
      
    >
    <div v-html="board.content"></div>

      <template #modal-footer="{ ok , closeModal  }">

        <b-button size="sm" variant="flat-danger" @click="not_show()">
          不再顯示
        </b-button>
        
    
     
      <b-button size="sm" variant="secondary" @click="close()">
        關閉
      </b-button>
    </template>
    </b-modal>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <!-- Bookmarks Container -->
      <center-drop-down />
      <reader-drop-down />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <!--  <search-bar />
      <cart-dropdown />
      <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  VBModal, BButton, BModal
} from 'bootstrap-vue'
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import CenterDropDown from './components/CenterDropDown.vue'
import ReaderDropDown from './components/ReaderDropDown.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
    VBModal, BButton, BModal,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    CenterDropDown,
    ReaderDropDown,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      board: {
        id : null,
        title : null,
        type : 'NOTICE',
        content : null,
        start_at : null,
        expired_at : null,
        created_at : null
      }
    }
  },
  created() {
  
  },
  mounted() {
    this.load_board()
  },
  methods: {
    close() {
      this.$refs['board-modal'].hide()
    },
    not_show() {
      console.log(this.board.id)
      this.$http.post(process.env.VUE_APP_API_BASE+'/board/seen/',{id:this.board.id})
        .then(res => {
          this.$refs['board-modal'].hide()
        })
    },
    load_board() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/board/')
        .then(res => {
          if (res.data.result === true) {
            this.board = res.data.notice
            this.$refs['board-modal'].show()
          }
        })
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
